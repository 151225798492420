import React from 'react'
import ConversationalRecruiting from '../components/recruitment_blogs/blogs/conversational_recruiting/conversational_recruiting'
import SEO from '../components/seo'

const Page = (location) => {
  return (<React.Fragment>
      <SEO title="5 Reasons to Switch to Conversational Recruiting in 2021?"
        description="Thinking about making the switch to conversational recruiting? This article shares 5 benefits of conversational recruiting, and how to achieve it."
        url="/conversational-recruiting/"
        image="https://blogs.mycareerdreams.com/blogs/conversational_banner.webp"
        type="article"
      />
    <ConversationalRecruiting location={location}/>
  </React.Fragment>
  )
}
export default Page;